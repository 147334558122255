import { CheckListLaborerEligibilityResponse } from 'api/contractAuthAPI'
import { Status, CallAPIAction } from 'api/types'
import Actions from 'redux/actions'

type State = {
  message?: string
} & Status

const initialState = {
  message: '',
  isLoading: false,
  success: false,
  fail: false,
}

export default (
  state: State = initialState,
  action: CallAPIAction<
    Actions.CHECK_LIST_LABORER_ELIGIBILITY_ASYNC,
    Actions.CHECK_LIST_LABORER_ELIGIBILITY_SUCCESS,
    Actions.CHECK_LIST_LABORER_ELIGIBILITY_FAIL,
    {},
    CheckListLaborerEligibilityResponse
  >,
): State => {
  switch (action.type) {
    case Actions.CHECK_LIST_LABORER_ELIGIBILITY_ASYNC:
    case Actions.CHECK_LIST_LABORER_ELIGIBILITY_SUCCESS: {
      return {
        ...state,
        ...action.status,
      }
    }
    case Actions.CHECK_LIST_LABORER_ELIGIBILITY_FAIL: {
      return {
        ...state,
        ...action.status,
        message: action.payload?.message,
      }
    }
    default:
      return state
  }
}
