import { takeLatest, call, put } from 'redux-saga/effects'
import { AxiosResponseHeaders } from 'axios'

import Actions from 'redux/actions'
import {
  getEmployerLaborers as getEmployerLaborersAction,
  getContractAuthRequestsList as getContractAuthRequestsListAction,
  createContractAuthRequest as createContractAuthRequestAction,
  getContractAuthDetailedRequests as getContractAuthDetailedRequestsAction,
  cancelContractAuthRequest as cancelContractAuthRequestAction,
  checkLaborerPhoneForContractAuth as checkLaborerPhoneForContractAuthActions,
  createContractAuthAppendix as createContractAuthRequestAppendixAction,
  updateLaborerMobileNumber as updateLaborerMobileNumberAction,
  cancelContractAuthAppendix as cancelContractAuthAppendixAction,
  getAuthContractDraft as getAuthContractDraftAction,
  getSimpleAuthContractInfo as getSimpleAuthContractInfoAction,
  getAuthContractPdf as getAuthContractPdfAction,
  getAuthContract as getAuthContractAction,
  getPendingAppendix as getPendingAppendixAction,
  sendLaborerReport as sendLaborerReportAction,
  cancelLaborerReport as cancelLaborerReportAction,
  checkLaborerHasReport as checkLaborerHasReportAction,
  checkListLaborerEligibility as checkListLaborerEligibilityAction,
  listLaborerCV as listLaborerCVAction,
  cancelListingLaborerCV as cancelListingLaborerCVAction,
  getListingLaborerCVRequest as getListingLaborerCVRequestAction,
  getListingCVsList as getListingCVsListAction,
} from 'redux/actionCreators/contractAuthAPI'
import contractAuthAPI, {
  CreateContractAuthRequestFailResponse,
  GetEmployerLaborersFailResponse,
} from 'api/contractAuthAPI'
import { history } from 'redux/browserHistory'
import { Routes } from 'containers/Routes/routePaths'
import {
  convertContractAuthEmloyerLaborers,
  convertMobileCheckResponse,
} from 'common/utils/convertResponse/convertAuthApiResponse'
import { checkLaborers } from 'common/utils/common'
import authManager from 'common/utils/auth/authManager'

import { getSimpleRequestHandler, putFail, putLoading, putSuccess } from '../utils'

function* getEmployerLaborers(action: ReturnType<typeof getEmployerLaborersAction>) {
  try {
    yield putLoading({ type: Actions.GET_CONTRACT_AUTH_EMLOYER_LABORERS_ASYNC, callAPIActionPayload: action.payload })
    const { response, headers } = yield call<typeof contractAuthAPI.getEmployerLaborers>(
      contractAuthAPI.getEmployerLaborers,
      action.payload.params,
      {},
      { silenceError: false },
    )
    const laborById = convertContractAuthEmloyerLaborers(response)
    yield putSuccess({
      type: Actions.GET_CONTRACT_AUTH_EMLOYER_LABORERS_SUCCESS,
      callAPIActionPayload: action.payload,
      payload: {
        laborById,
      },
      headers,
    })
    !action.payload.params.wps &&
      history.push(authManager.isVipUser() ? Routes.VIP_CONTRACT_AUTH_INSTRUCTION : Routes.CONTRACT_AUTH_INSTRUCTION)
  } catch (err) {
    const { response, headers } = (err || {}) as {
      response?: GetEmployerLaborersFailResponse
      headers?: AxiosResponseHeaders
    }
    yield putFail({
      type: Actions.GET_CONTRACT_AUTH_EMLOYER_LABORERS_FAIL,
      callAPIActionPayload: action.payload,
      payload: {
        errorMessage: response?.error?.message || '',
      },
      headers,
    })
  }
}

function* checkEmployerLaborersForAuthServiceBanner(action: ReturnType<typeof getEmployerLaborersAction>) {
  const { response } = yield call<typeof contractAuthAPI.getEmployerLaborers>(
    contractAuthAPI.getEmployerLaborers,
    action.payload.params,
    {},
    { silenceError: false },
  )
  const showServiceBanner = response && checkLaborers(response)
  if (showServiceBanner) {
    yield put({ type: Actions.OPEN_AUTH_BANNER })
  } else {
    yield put({ type: Actions.CLOSE_AUTH_BANNER })
  }
}

const getContractAuthRequestsList = getSimpleRequestHandler<
  typeof contractAuthAPI.getContractAuthRequestsList,
  ReturnType<typeof getContractAuthRequestsListAction>
>({
  actionLoading: Actions.GET_CONTRACT_AUTH_REQUESTS_LIST_ASYNC,
  actionSuccess: Actions.GET_CONTRACT_AUTH_REQUESTS_LIST_SUCCESS,
  actionFail: Actions.GET_CONTRACT_AUTH_REQUESTS_LIST_FAIL,
  callApiFn: contractAuthAPI.getContractAuthRequestsList,
})

const createContractAuthRequest = getSimpleRequestHandler<
  typeof contractAuthAPI.createContractAuthRequest,
  ReturnType<typeof createContractAuthRequestAction>
>({
  actionLoading: Actions.CREATE_CONTRACT_AUTH_REQUEST_ASYNC,
  actionSuccess: Actions.CREATE_CONTRACT_AUTH_REQUEST_SUCCESS,
  actionFail: Actions.CREATE_CONTRACT_AUTH_REQUEST_FAIL,
  callApiFn: contractAuthAPI.createContractAuthRequest,
})

const getContractAuthDetailedRequest = getSimpleRequestHandler<
  typeof contractAuthAPI.getContractAuthDetailedRequests,
  ReturnType<typeof getContractAuthDetailedRequestsAction>
>({
  actionLoading: Actions.GET_CONTRACT_AUTH_DETAILED_REQUEST_ASYNC,
  actionSuccess: Actions.GET_CONTRACT_AUTH_DETAILED_REQUEST_SUCCESS,
  actionFail: Actions.GET_CONTRACT_AUTH_DETAILED_REQUEST_FAIL,
  callApiFn: contractAuthAPI.getContractAuthDetailedRequests,
})

const cancelContractAuthRequest = getSimpleRequestHandler<
  typeof contractAuthAPI.cancelContractAuthRequest,
  ReturnType<typeof cancelContractAuthRequestAction>
>({
  actionLoading: Actions.CANCEL_CONTRACT_AUTH_REQUEST_ASYNC,
  actionSuccess: Actions.CANCEL_CONTRACT_AUTH_REQUEST_SUCCESS,
  actionFail: Actions.CANCEL_CONTRACT_AUTH_REQUEST_FAIL,
  callApiFn: contractAuthAPI.cancelContractAuthRequest,
})

function* checkLaborerPhoneForContractAuth(action: ReturnType<typeof checkLaborerPhoneForContractAuthActions>) {
  try {
    yield putLoading({
      type: Actions.CHECK_LABORER_PHONE_FOR_CONTRACT_AUTH_ASYNC,
      callAPIActionPayload: action.payload,
    })
    const { response, headers } = yield call<typeof contractAuthAPI.checkLaborerPhoneForContractAuth>(
      contractAuthAPI.checkLaborerPhoneForContractAuth,
      action.payload.params,
      {},
      { silenceError: false },
    )
    const payload = convertMobileCheckResponse(response)
    yield putSuccess({
      type: Actions.CHECK_LABORER_PHONE_FOR_CONTRACT_AUTH_SUCCESS,
      callAPIActionPayload: action.payload,
      payload,
      headers,
    })
    if (!payload.isUsed) {
      history.push(
        authManager.isVipUser() ? Routes.VIP_CONTRACT_AUTH_CONTRACT_PREVIEW : Routes.CONTRACT_AUTH_CONTRACT_PREVIEW,
      )
    }
  } catch (err) {
    const errorMessage = (err as { response: CreateContractAuthRequestFailResponse }).response.error?.message || ''
    yield putFail({
      type: Actions.CHECK_LABORER_PHONE_FOR_CONTRACT_AUTH_FAIL,
      callAPIActionPayload: action.payload,
      payload: { errorMessage },
      headers: {},
    })
  }
}

const createContractAuthRequestAppendix = getSimpleRequestHandler<
  typeof contractAuthAPI.createContractAuthRequestAppendix,
  ReturnType<typeof createContractAuthRequestAppendixAction>
>({
  actionLoading: Actions.CREATE_CONTRACT_AUTH_APPENDIX_ASYNC,
  actionSuccess: Actions.CREATE_CONTRACT_AUTH_APPENDIX_SUCCESS,
  actionFail: Actions.CREATE_CONTRACT_AUTH_APPENDIX_FAIL,
  callApiFn: contractAuthAPI.createContractAuthRequestAppendix,
})

const updateLaborerMobileNumber = getSimpleRequestHandler<
  typeof contractAuthAPI.updateLaborerMobileNumber,
  ReturnType<typeof updateLaborerMobileNumberAction>
>({
  actionLoading: Actions.UPDATE_LABORER_MOBILE_NUMBER_ASYNC,
  actionSuccess: Actions.UPDATE_LABORER_MOBILE_NUMBER_SUCCESS,
  actionFail: Actions.UPDATE_LABORER_MOBILE_NUMBER_FAIL,
  callApiFn: contractAuthAPI.updateLaborerMobileNumber,
})

const cancelContractAuthAppendix = getSimpleRequestHandler<
  typeof contractAuthAPI.cancelContractAuthAppendix,
  ReturnType<typeof cancelContractAuthAppendixAction>
>({
  actionLoading: Actions.CANCEL_CONTRACT_AUTH_APPENDIX_ASYNC,
  actionSuccess: Actions.CANCEL_CONTRACT_AUTH_APPENDIX_SUCCESS,
  actionFail: Actions.CANCEL_CONTRACT_AUTH_APPENDIX_FAIL,
  callApiFn: contractAuthAPI.cancelContractAuthAppendix,
})

const getAuthContractDraft = getSimpleRequestHandler<
  typeof contractAuthAPI.getAuthContractDraft,
  ReturnType<typeof getAuthContractDraftAction>
>({
  actionLoading: Actions.GET_CONTRACT_AUTH_DRAFT_ASYNC,
  actionSuccess: Actions.GET_CONTRACT_AUTH_DRAFT_SUCCESS,
  actionFail: Actions.GET_CONTRACT_AUTH_DRAFT_FAIL,
  callApiFn: contractAuthAPI.getAuthContractDraft,
})

const getSimpleAuthContractInfo = getSimpleRequestHandler<
  typeof contractAuthAPI.getSimpleAuthContractInfo,
  ReturnType<typeof getSimpleAuthContractInfoAction>
>({
  actionLoading: Actions.GET_SIMPLE_AUTH_CONTRACT_INFO_ASYNC,
  actionSuccess: Actions.GET_SIMPLE_AUTH_CONTRACT_INFO_SUCCESS,
  actionFail: Actions.GET_SIMPLE_AUTH_CONTRACT_INFO_FAIL,
  callApiFn: contractAuthAPI.getSimpleAuthContractInfo,
})

const getAuthContractPdf = getSimpleRequestHandler<
  typeof contractAuthAPI.getAuthContractPdf,
  ReturnType<typeof getAuthContractPdfAction>
>({
  actionLoading: Actions.GET_AUTH_CONTRACT_PDF_ASYNC,
  actionSuccess: Actions.GET_AUTH_CONTRACT_PDF_SUCCESS,
  actionFail: Actions.GET_AUTH_CONTRACT_PDF_FAIL,
  callApiFn: contractAuthAPI.getAuthContractPdf,
})

const getAuthContract = getSimpleRequestHandler<
  typeof contractAuthAPI.getAuthContract,
  ReturnType<typeof getAuthContractAction>
>({
  actionLoading: Actions.GET_AUTH_CONTRACT_ASYNC,
  actionSuccess: Actions.GET_AUTH_CONTRACT_SUCCESS,
  actionFail: Actions.GET_AUTH_CONTRACT_FAIL,
  callApiFn: contractAuthAPI.getAuthContract,
})

const getPendingAppendix = getSimpleRequestHandler<
  typeof contractAuthAPI.getPendingAppendix,
  ReturnType<typeof getPendingAppendixAction>
>({
  actionLoading: Actions.GET_PENDING_APPENDIX_ASYNC,
  actionSuccess: Actions.GET_PENDING_APPENDIX_SUCCESS,
  actionFail: Actions.GET_PENDING_APPENDIX_FAIL,
  callApiFn: contractAuthAPI.getPendingAppendix,
})

const sendLaborerReport = getSimpleRequestHandler<
  typeof contractAuthAPI.sendLaborerReport,
  ReturnType<typeof sendLaborerReportAction>
>({
  actionLoading: Actions.SEND_LABORER_REPORT_ASYNC,
  actionSuccess: Actions.SEND_LABORER_REPORT_SUCCESS,
  actionFail: Actions.SEND_LABORER_REPORT_FAIL,
  callApiFn: contractAuthAPI.sendLaborerReport,
})

const cancelLaborerReport = getSimpleRequestHandler<
  typeof contractAuthAPI.cancelLaborerReport,
  ReturnType<typeof cancelLaborerReportAction>
>({
  actionLoading: Actions.CANCEL_LABORER_REPORT_ASYNC,
  actionSuccess: Actions.CANCEL_LABORER_REPORT_SUCCESS,
  actionFail: Actions.CANCEL_LABORER_REPORT_FAIL,
  callApiFn: contractAuthAPI.cancelLaborerReport,
})

const checkLaborerHasReport = getSimpleRequestHandler<
  typeof contractAuthAPI.checkLaborerHasReport,
  ReturnType<typeof checkLaborerHasReportAction>
>({
  actionLoading: Actions.CHECK_LABORER_HAS_REPORT_ASYNC,
  actionSuccess: Actions.CHECK_LABORER_HAS_REPORT_SUCCESS,
  actionFail: Actions.CHECK_LABORER_HAS_REPORT_FAIL,
  callApiFn: contractAuthAPI.checkLaborerHasReport,
})

const checkListLaborerEligibility = getSimpleRequestHandler<
  typeof contractAuthAPI.checkListLaborerEligibility,
  ReturnType<typeof checkListLaborerEligibilityAction>
>({
  actionLoading: Actions.CHECK_LIST_LABORER_ELIGIBILITY_ASYNC,
  actionSuccess: Actions.CHECK_LIST_LABORER_ELIGIBILITY_SUCCESS,
  actionFail: Actions.CHECK_LIST_LABORER_ELIGIBILITY_FAIL,
  callApiFn: contractAuthAPI.checkListLaborerEligibility,
})

const listLaborerCV = getSimpleRequestHandler<
  typeof contractAuthAPI.listLaborerCV,
  ReturnType<typeof listLaborerCVAction>
>({
  actionLoading: Actions.LIST_LABORER_CV_ASYNC,
  actionSuccess: Actions.LIST_LABORER_CV_SUCCESS,
  actionFail: Actions.LIST_LABORER_CV_FAIL,
  callApiFn: contractAuthAPI.listLaborerCV,
})

const cancelListingLaborerCV = getSimpleRequestHandler<
  typeof contractAuthAPI.cancelListingLaborerCV,
  ReturnType<typeof cancelListingLaborerCVAction>
>({
  actionLoading: Actions.CANCEL_LISTING_LABORER_CV_ASYNC,
  actionSuccess: Actions.CANCEL_LISTING_LABORER_CV_SUCCESS,
  actionFail: Actions.CANCEL_LISTING_LABORER_CV_FAIL,
  callApiFn: contractAuthAPI.cancelListingLaborerCV,
})

const getListingLaborerCVRequest = getSimpleRequestHandler<
  typeof contractAuthAPI.getListingLaborerCVRequest,
  ReturnType<typeof getListingLaborerCVRequestAction>
>({
  actionLoading: Actions.GET_LISTING_LABORER_CV_REQUEST_ASYNC,
  actionSuccess: Actions.GET_LISTING_LABORER_CV_REQUEST_SUCCESS,
  actionFail: Actions.GET_LISTING_LABORER_CV_REQUEST_FAIL,
  callApiFn: contractAuthAPI.getListingLaborerCVRequest,
})

const getListingCVsList = getSimpleRequestHandler<
  typeof contractAuthAPI.getListingCVsList,
  ReturnType<typeof getListingCVsListAction>
>({
  actionLoading: Actions.GET_LISTING_CVS_LIST_ASYNC,
  actionSuccess: Actions.GET_LISTING_CVS_LIST_SUCCESS,
  actionFail: Actions.GET_LISTING_CVS_LIST_FAIL,
  callApiFn: contractAuthAPI.getListingCVsList,
})

export default [
  takeLatest(Actions.GET_CONTRACT_AUTH_EMLOYER_LABORERS, getEmployerLaborers),
  takeLatest(Actions.GET_CONTRACT_AUTH_REQUESTS_LIST, getContractAuthRequestsList),
  takeLatest(Actions.CREATE_CONTRACT_AUTH_REQUEST, createContractAuthRequest),
  takeLatest(Actions.GET_CONTRACT_AUTH_DETAILED_REQUEST, getContractAuthDetailedRequest),
  takeLatest(Actions.CANCEL_CONTRACT_AUTH_REQUEST, cancelContractAuthRequest),
  takeLatest(Actions.CHECK_LABORER_PHONE_FOR_CONTRACT_AUTH, checkLaborerPhoneForContractAuth),
  takeLatest(Actions.CREATE_CONTRACT_AUTH_APPENDIX, createContractAuthRequestAppendix),
  takeLatest(Actions.CHECK_LABORERS_FOR_AUTH_BANNER, checkEmployerLaborersForAuthServiceBanner),
  takeLatest(Actions.UPDATE_LABORER_MOBILE_NUMBER, updateLaborerMobileNumber),
  takeLatest(Actions.CANCEL_CONTRACT_AUTH_APPENDIX, cancelContractAuthAppendix),
  takeLatest(Actions.GET_CONTRACT_AUTH_DRAFT, getAuthContractDraft),
  takeLatest(Actions.GET_SIMPLE_AUTH_CONTRACT_INFO, getSimpleAuthContractInfo),
  takeLatest(Actions.GET_AUTH_CONTRACT_PDF, getAuthContractPdf),
  takeLatest(Actions.GET_AUTH_CONTRACT, getAuthContract),
  takeLatest(Actions.GET_PENDING_APPENDIX, getPendingAppendix),
  takeLatest(Actions.SEND_LABORER_REPORT, sendLaborerReport),
  takeLatest(Actions.CANCEL_LABORER_REPORT, cancelLaborerReport),
  takeLatest(Actions.CHECK_LABORER_HAS_REPORT, checkLaborerHasReport),
  takeLatest(Actions.CHECK_LIST_LABORER_ELIGIBILITY, checkListLaborerEligibility),
  takeLatest(Actions.LIST_LABORER_CV, listLaborerCV),
  takeLatest(Actions.CANCEL_LISTING_LABORER_CV, cancelListingLaborerCV),
  takeLatest(Actions.GET_LISTING_LABORER_CV_REQUEST, getListingLaborerCVRequest),
  takeLatest(Actions.GET_LISTING_CVS_LIST, getListingCVsList),
]
