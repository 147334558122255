import { GetListingCVsListResponse } from 'api/contractAuthAPI'
import { CallAPIAction, Status } from 'api/types'
import { ListingCVRequestFromBE, ListingCVRequest } from 'common/types/commonTypes'
import Actions from 'redux/actions'

type State = {
  data: Array<ListingCVRequest>
  lastPage: number
} & Status

const initialState: State = {
  isLoading: false,
  success: false,
  fail: false,
  data: [],
  lastPage: 0,
}

const convertPayloadToState = (item: ListingCVRequestFromBE) => ({
  id: item.id,
  occupation: {
    id: 0,
    label: item.occupation.label,
    labelEn: item.occupation.label_en,
  },
  employer: item.employer,
  status: item.status,
  birthDate: item.birth_date,
  experienceInSaudi: item.experience_in_saudi,
  gender: item.gender,
  iqamaNumber: item.iqama_number,
  profileImage: item.laborer_profile,
  martialStatus: item.martial_status,
  name: {
    id: 0,
    label: item.name,
    labelEn: item.name_en,
  },
  city: {
    id: 0,
    label: item.city.label_ar,
    labelEn: item.city.label_en,
  },
  nationality: {
    id: 0,
    label: item.nationality.label,
    labelEn: item.nationality.label_en,
  },
  qualification: item.qualification || '-',
  religion: item.religion,
  salary: item.salary,
  employerMobile: item.employer_mobile,
  createdAt: item.created_at,
  laborerMobile: item.laborer_mobile || '-',
  serviceCost: item.transfer_service_cost,
})

export default (
  state: State = initialState,
  action: CallAPIAction<
    Actions.GET_LISTING_CVS_LIST_ASYNC,
    Actions.GET_LISTING_CVS_LIST_SUCCESS,
    Actions.GET_LISTING_CVS_LIST_FAIL,
    GetListingCVsListResponse
  >,
) => {
  switch (action.type) {
    case Actions.GET_LISTING_CVS_LIST_SUCCESS:
      return {
        ...state,
        ...action.status,
        data: action.payload.data.map(convertPayloadToState),
        lastPage: action.payload.meta.last_page,
      }
    case Actions.GET_LISTING_CVS_LIST_ASYNC:
    case Actions.GET_LISTING_CVS_LIST_FAIL:
      return {
        ...state,
        ...action.status,
      }
    default:
      return state
  }
}
