import { CallAPIAction, Status } from 'api/types'
import Actions from 'redux/actions'

type State = Status

const initialState: Status = {
  isLoading: false,
  success: false,
  fail: false,
}

export default (
  state: State = initialState,
  action: CallAPIAction<
    Actions.CANCEL_LISTING_LABORER_CV_ASYNC,
    Actions.CANCEL_LISTING_LABORER_CV_SUCCESS,
    Actions.CANCEL_LISTING_LABORER_CV_FAIL,
    {}
  >,
) => {
  switch (action.type) {
    case Actions.CANCEL_LISTING_LABORER_CV_SUCCESS:
    case Actions.CANCEL_LISTING_LABORER_CV_ASYNC:
    case Actions.CANCEL_LISTING_LABORER_CV_FAIL:
      return {
        ...state,
        ...action.status,
      }
    default:
      return state
  }
}
