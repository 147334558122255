import { GetListingLaborerOffersParams } from 'redux/reducers/listLaborerFilter'

import Actions from '../actions'

type FilterValues = Partial<GetListingLaborerOffersParams>

export const setListLaborerFilter = (filter: FilterValues) => ({
  type: Actions.SET_LIST_LABORER_FILTER,
  payload: {
    filter: {
      page: 0,
      clearPagesCount: true,
      ...filter,
    },
  },
})
