import React, { memo, FunctionComponent, useState, useCallback, useMemo, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import clsx from 'clsx'

import { RootState } from 'redux/rootReducer'
import { addTranslationNamespace, TranslationNamespace } from 'common/utils/translation'
import { useVipRoute } from 'common/hooks/useVipRoute'
import Modal from 'ui-components/modals/Modal/Modal'
import Typography from 'ui-components/typography/Typography'
import Button from 'ui-components/buttons/Button/Button'
import { setLanguage } from 'redux/actionCreators/language'
import { Lang } from 'common/types/commonTypes'
import { closeLanguageModal } from 'redux/actionCreators/languageModal'

import { ReactComponent as CloseIcon } from 'images/close-icon.svg'

import languageModalEn from './LanguageModal_en.json'
import languageModalAr from './LanguageModal_ar.json'
import languageModalUr from './LanguageModal_ur.json'
import languageModalAm from './LanguageModal_am.json'
import languageModalFil from './LanguageModal_fil.json'
import languageModalHi from './LanguageModal_hi.json'
import styles from './LanguageModal.module.scss'

const options = [
  { value: 1, labelTKey: 'ar' },
  { value: 2, labelTKey: 'en' },
  { value: 3, labelTKey: 'ur', subLabel: 'urSub' },
  { value: 4, labelTKey: 'am', subLabel: 'amSub' },
  { value: 5, labelTKey: 'fil' },
  { value: 6, labelTKey: 'hi', subLabel: 'hiSub' },
]

const LanguageModal: FunctionComponent = () => {
  const dispatch = useDispatch()
  const { isVip } = useVipRoute()
  const { t } = useTranslation(TranslationNamespace.languageModal)
  const { open, lang } = useSelector((state: RootState) => ({
    open: state.languageModal.open,
    lang: state.lang,
  }))

  const [selectedLang, setSelectedLang] = useState(options.find((option) => option.labelTKey === lang)?.value || 1)

  const setNewLang = (value: number) => {
    switch (value) {
      case 1:
        return Lang.ar
      case 2:
        return Lang.en
      case 3:
        return Lang.ur
      case 4:
        return Lang.am
      case 5:
        return Lang.fil
      case 6:
        return Lang.hi
      default:
        return lang
    }
  }

  useEffect(() => {
    setSelectedLang(options.find((option) => option.labelTKey === lang)?.value || 1)
  }, [lang])

  const handleLanguageChange = useCallback((value) => {
    setSelectedLang(value)
  }, [])

  const handleCloseModal = useCallback(() => {
    dispatch(closeLanguageModal())
  }, [dispatch])

  const handleChooseLanguage = useCallback(() => {
    dispatch(setLanguage(setNewLang(selectedLang)))
    handleCloseModal()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, selectedLang, handleCloseModal])

  const renderRadioButtons = useMemo(
    () =>
      options.map((option) => (
        <div
          className={clsx(styles.radioItem, selectedLang === option.value && styles.selected)}
          key={option.value}
          onClick={() => handleLanguageChange(option.value)}
        >
          <div className={styles.labelContainer}>
            <span className={clsx(styles.label, isVip && styles.labelVip)}>{t(option.labelTKey)}</span>
            {option.subLabel && <span className={styles.subLabel}>{t(option.subLabel)}</span>}
          </div>
          <input
            type='radio'
            name={`group-${option.subLabel} ${option.value}`}
            className={clsx(styles.input, isVip && styles.inputVip)}
            id={`input-${option.subLabel}-${option.value}`}
            checked={selectedLang === option.value}
          />
        </div>
      )),
    [selectedLang, t, isVip, handleLanguageChange],
  )

  return (
    <Modal open={open} onClose={handleCloseModal}>
      <CloseIcon className={styles.closeIcon} onClick={handleCloseModal} />
      <div className={styles.languageModal}>
        <Typography appearance='subtitle' color='black' className={styles.subtitle}>
          {t('title')}
        </Typography>
        <div className={styles.description}>{t('description')}</div>
        <div className={styles.radioContainer}>{renderRadioButtons}</div>
        <div className={styles.buttonContainer}>
          <Button type='tertiary' onClick={handleCloseModal}>
            {t('cancel')}
          </Button>
          <Button onClick={handleChooseLanguage}>{t('choose')}</Button>
        </div>
      </div>
    </Modal>
  )
}

export default memo(LanguageModal)

addTranslationNamespace(
  TranslationNamespace.languageModal,
  languageModalEn,
  languageModalAr,
  languageModalUr,
  languageModalAm,
  languageModalFil,
  languageModalHi,
)
