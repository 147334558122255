import { ListLaborerCVFailResponse } from 'api/contractAuthAPI'
import { CallAPIAction, Status } from 'api/types'
import Actions from 'redux/actions'

type State = {
  errorMessage: string
} & Status

const initialState: State = {
  errorMessage: '',
  isLoading: false,
  success: false,
  fail: false,
}

export default (
  state: State = initialState,
  action: CallAPIAction<
    Actions.LIST_LABORER_CV_ASYNC,
    Actions.LIST_LABORER_CV_SUCCESS,
    Actions.LIST_LABORER_CV_FAIL,
    {},
    ListLaborerCVFailResponse
  >,
) => {
  switch (action.type) {
    case Actions.LIST_LABORER_CV_SUCCESS:
    case Actions.LIST_LABORER_CV_ASYNC:
      return {
        ...state,
        ...action.status,
      }

    case Actions.LIST_LABORER_CV_FAIL:
      return {
        ...state,
        ...action.status,
        errorMessage: action.payload?.message ?? '',
      }
    default:
      return state
  }
}
