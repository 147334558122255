import Actions from 'redux/actions'
import { Nationality, Occupation } from 'common/types/transformedRespTypes'
import { setListLaborerFilter as setListLaborerFilterAction } from 'redux/actionCreators/listingLaborerAPI'

export type GetListingLaborerOffersParams = {
  page: number
  gender?: string
  occupation?: number
  nationality?: number
  nationalityInfo?: Nationality
  occupationInfo?: Occupation
  arrivalCityId?: number
  religion?: string
  age?: number
  experience?: number
}

const initialState: GetListingLaborerOffersParams = {
  page: 0,
  gender: undefined,
  occupation: undefined,
  nationality: undefined,
}

const setListLaborerFilter = (
  state: GetListingLaborerOffersParams = initialState,
  action: ReturnType<typeof setListLaborerFilterAction>,
): GetListingLaborerOffersParams => {
  switch (action.type) {
    case Actions.SET_LIST_LABORER_FILTER:
      return {
        ...state,
        ...action.payload.filter,
      }
    default:
      return state
  }
}

export default setListLaborerFilter
